import React, { useState, useEffect } from "react";
import axios from "axios";
import { savePatientToIndexedDB } from "../indexedDB"; // Importing IndexedDB helper function

const PatientForm = ({ mode = "create", patientId = null }) => {
  const [patient, setPatient] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    address: "",
    genderIdentity: "",
    telephone: "",
    nhsNumber: "",
  });

  const [status, setStatus] = useState({ message: "", type: "" });

  // Fetch existing patient data if in view mode and patientId is provided
  useEffect(() => {
    if (mode === "view" && patientId) {
      const fetchPatient = async () => {
        try {
          const response = await axios.get(`/api/epcr/patients/${patientId}`);
          setPatient(response.data);
        } catch (error) {
          setStatus({ message: "Failed to load patient data.", type: "error" });
          console.error("Error fetching patient data:", error);
        }
      };
      fetchPatient();
    }
  }, [mode, patientId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPatient((prevPatient) => ({
      ...prevPatient,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mode === "view") return; // Prevent submission if in view mode

    setStatus({ message: "", type: "" });
    if (navigator.onLine) {
      try {
        await axios.post("/api/epcr/patients", patient);
        setStatus({ message: "Patient record saved online!", type: "success" });
      } catch (error) {
        console.error("Error saving patient record online:", error);
        setStatus({ message: "Failed to save patient online.", type: "error" });
      }
    } else {
      try {
        await savePatientToIndexedDB(patient);
        setStatus({
          message: "You are offline. Patient record saved locally.",
          type: "info",
        });
      } catch (error) {
        console.error("Error saving patient record locally:", error);
        setStatus({ message: "Failed to save patient locally.", type: "error" });
      }
    }
  };

  return (
    <div style={{ maxWidth: "600px", margin: "auto" }}>
      <h2>{mode === "create" ? "Create New Patient" : "View Patient Details"}</h2>
      <form onSubmit={handleSubmit}>
        {/* Render form fields dynamically */}
        {Object.keys(patient).map((key) => (
          <div key={key} style={{ marginBottom: "15px" }}>
            <label>
              {key.charAt(0).toUpperCase() + key.slice(1)}:
              <input
                type="text"
                name={key}
                value={patient[key] || ""}
                onChange={handleChange}
                disabled={mode === "view"} // Disable fields in view mode
                required={mode === "create"} // Make fields required in create mode
                style={{ width: "100%", padding: "8px" }}
              />
            </label>
          </div>
        ))}
        {mode === "create" && <button type="submit">Save Patient</button>}
        {/* Display status message */}
        {status.message && (
          <p
            style={{
              color:
                status.type === "success"
                  ? "green"
                  : status.type === "error"
                  ? "red"
                  : "blue",
            }}
          >
            {status.message}
          </p>
        )}
      </form>
    </div>
  );
};

export default PatientForm;
