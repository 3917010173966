import { openDB } from "idb";
import CryptoJS from 'crypto-js';  // Import for encryption

const encryptionKey = 'your-secret-key'; // Replace with a securely generated key

const dbPromise = openDB("epcr-db", 1, {
  upgrade(db) {
    db.createObjectStore("patients", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("allergies", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("anatomicalExam", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("attachedDocumentation", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("burns", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("cannulation", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("clinicalImpression", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("clinicalInterventions", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("clinicalNotes", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("closeOff", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("crewSignatures", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("incidents", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("inventory", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("inventoryAllocation", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("inventoryUsage", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("linkedAudits", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("medicationAdministered", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("medications", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("observations", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("presentingComplaint", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("primarySurvey", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("procedures", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("role", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("secondarySurvey", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("specialReports", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("staff", { keyPath: "id", autoIncrement: true });
    db.createObjectStore("storageLocations", { keyPath: "id", autoIncrement: true });
  },
});

// Helper function to encrypt data
function encryptData(data) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
}

// Helper function to decrypt data
function decryptData(encryptedData) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

// Save data with encryption to IndexedDB
export async function savePatientToIndexedDB(patient) {
  const db = await dbPromise;
  const encryptedPatient = encryptData(patient);  // Encrypt patient data
  await db.put('patients', { ...patient, encryptedPatient });  // Save the encrypted version
}

// Retrieve and decrypt data from IndexedDB
export async function getPatientsFromIndexedDB() {
  const db = await dbPromise;
  const patients = await db.getAll('patients');
  return patients.map(patient => decryptData(patient.encryptedPatient));  // Decrypt each patient
}

// IndexedDB functions for Clinical Notes
export async function saveClinicalNoteToIndexedDB(clinicalNote) {
  const db = await dbPromise;
  await db.put('clinicalNotes', clinicalNote);
}

export async function getClinicalNotesFromIndexedDB() {
  const db = await dbPromise;
  return await db.getAll('clinicalNotes');
}

// IndexedDB functions for Allergies
export async function saveAllergyToIndexedDB(allergy) {
  const db = await dbPromise;
  await db.put('allergies', allergy);
}

export async function getAllergiesFromIndexedDB() {
  const db = await dbPromise;
  return await db.getAll('allergies');
}

