import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Container,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DCLogo from "../assets/images/DCLogo.png"; // Ensure correct path
import { useAuth } from "../../context/AuthContext"; // Import useAuth hook

const Navbar = () => {
  const { user, logout } = useAuth(); // Access user and logout function
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEls, setAnchorEls] = useState({}); // Object to store anchor elements for each menu

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Function to open a specific submenu
  const handleMenuOpen = (event, menuName) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [menuName]: event.currentTarget,
    }));
  };

  // Function to close a specific submenu
  const handleMenuClose = (menuName) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [menuName]: null,
    }));
  };

  const navLinks = [
    { label: "Home", path: "/", icon: <HomeIcon /> },
  ];

  return (
    <AppBar position="static" sx={{ bgcolor: "primary.main" }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              component="img"
              src={DCLogo}
              link={"/"}
              alt="DC Event Medical Logo"
              sx={{
                height: { xs: 40, sm: 50, md: 60 }, // Responsive logo size
                width: "auto",
                mr: 2,
              }}
            />
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" }, // Responsive font size
                  fontWeight: "bold",
                }}
              >
                DC Event Medical
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: "white",
                  fontSize: { xs: "0.75rem", sm: "0.85rem", md: "0.95rem" }, // Responsive font size
                  display: { xs: "none", sm: "block" }, // Hide on extra small screens
                }}
              >
                "Care Beyond Expectation"
              </Typography>
            </Box>
          </Box>
          {/* Mobile Menu Button */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          {/* Desktop Navigation Links */}
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
            {navLinks.map((link, index) => (
              <div key={index}>
                {link.subMenu ? (
                  <>
                    <Button
                      color="inherit"
                      startIcon={link.icon}
                      sx={{ color: "white" }}
                      onClick={(event) =>
                        handleMenuOpen(event, `menu-${index}`)
                      }
                    >
                      {link.label}
                    </Button>
                    <Menu
                      anchorEl={anchorEls[`menu-${index}`]}
                      open={Boolean(anchorEls[`menu-${index}`])}
                      onClose={() => handleMenuClose(`menu-${index}`)}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      {link.subMenu.map((subLink, subIndex) => (
                        <MenuItem
                          key={subIndex}
                          component={Link}
                          to={subLink.path}
                          onClick={() => handleMenuClose(`menu-${index}`)}
                        >
                          <ListItemIcon>{subLink.icon || null}</ListItemIcon>
                          {subLink.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                ) : (
                  <Button
                    key={index}
                    color="inherit"
                    component={Link}
                    to={link.path}
                    startIcon={link.icon}
                    sx={{ color: "white" }}
                  >
                    {link.label}
                  </Button>
                )}
              </div>
            ))}
            {user ? (
              <Button
                color="inherit"
                startIcon={<LogoutIcon />}
                sx={{ color: "white" }}
                onClick={logout}
              >
                Logout
              </Button>
            ) : (
              <Button
                color="inherit"
                component={Link}
                to="/login"
                sx={{ color: "white" }}
              >
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { xs: "flex", md: "none" } }}
      >
        <Box
          sx={{
            width: 250,
            bgcolor: "background.paper",
            height: "100%",
          }}
          role="presentation"
          onClick={handleDrawerToggle}
          onKeyDown={handleDrawerToggle}
        >
          <List>
            {navLinks.map((link, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton component={Link} to={link.path}>
                  <ListItemIcon>{link.icon}</ListItemIcon>
                  <ListItemText primary={link.label} />
                </ListItemButton>
              </ListItem>
            ))}
            <Divider />
            {user ? (
              <ListItem disablePadding>
                <ListItemButton onClick={logout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </ListItem>
            ) : (
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/login">
                  <ListItemText primary="Login" />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
