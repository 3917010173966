// components/common/Loader.js
import React from "react";
import { Box, CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh" // Ensures the loader is centered in the viewport
    >
      <CircularProgress size={60} thickness={5} color="primary" />
    </Box>
  );
};

export default Loader;
