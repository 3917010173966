// src/context/AuthContext.js

import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import requestHandler from "../components/common/requestHandler";
import jwt from "jsonwebtoken";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authError, setAuthError] = useState(""); // Error state for authentication
  const navigate = useNavigate();

  useEffect(() => {
    // Check if a token is already stored
    const token = localStorage.getItem("authToken");
    if (token) {
      try {
        const decodedUser = jwt.decode(token);
        if (!decodedUser) throw new Error("Invalid token");

        // Check if the token is expired
        const isExpired = decodedUser.exp * 1000 < Date.now();
        if (isExpired) {
          console.log("Token is expired. Logging out...");
          logout(); // Log out if the token is expired
        } else {
          console.log("Setting user from token", decodedUser); // Debug statement
          setUser(decodedUser); // Set user if the token is valid
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        setAuthError("Session error. Please log in again.");
        logout(); // Ensure logout on error
      }
    }
  }, []);

  const login = async (email, password) => {
    try {
      setAuthError(""); // Clear previous errors
      const data = await requestHandler("/api/auth/login", "POST", {
        email,
        password,
      });
      const { token } = data;
      localStorage.setItem("authToken", token);
      const decodedUser = jwt.decode(token); // Decode the token to get user data
      if (!decodedUser) throw new Error("Invalid login response token");

      console.log("User logged in as:", decodedUser.role); // Debug statement
      setUser(decodedUser); // Include role and other details
      navigate("/");
    } catch (error) {
      console.error("Error during login:", error);
      setAuthError(
        "Login failed. Please check your credentials and try again."
      );
    }
  };

  const register = async (name, email, password) => {
    try {
      setAuthError(""); // Clear previous errors
      const response = await requestHandler("/api/auth/register", "POST", {
        name,
        email,
        password,
      });
      const { token } = response.data;
      localStorage.setItem("authToken", token);
      const decodedUser = jwt.decode(token); // Decode the token to get user data
      if (!decodedUser) throw new Error("Invalid registration response token");

      console.log("User registered as:", decodedUser.role); // Debug statement
      setUser(decodedUser); // Include role and other details
      navigate("/");
    } catch (error) {
      console.error("Error during registration:", error);
      setAuthError("Registration failed. Please try again.");
    }
  };

  const logout = () => {
    console.log("Logging out...");
    localStorage.removeItem("authToken");
    setUser(null);
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ user, login, register, logout, authError }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
