import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Chip,
} from "@mui/material";
import requestHandler from "../components/common/requestHandler";

const ApplicantList = () => {
  const [applicants, setApplicants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const data = await requestHandler("/api/recruitment", "GET");
        setApplicants(data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load applicants");
        setLoading(false);
      }
    };

    fetchApplicants();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 3, mb: 5 }}>
        <Typography variant="h4" gutterBottom color="primary.main">
          Applicant List
        </Typography>
        <List>
          {applicants.map((applicant) => (
            <ListItem
              key={applicant._id}
              component={Link}
              to={`/applicants/${applicant._id}`}
            >
              <ListItemText
                primary={applicant.fullName}
                secondary={`Status: ${applicant.applicationStatus}`} // Display status
              />
              <Chip
                label={applicant.applicationStatus}
                color={
                  applicant.status === "Accepted"
                    ? "success"
                    : applicant.status === "Rejected"
                    ? "error"
                    : "default"
                }
                sx={{ ml: 2 }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

export default ApplicantList;
