import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Divider,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Box,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import NewIncidentForm from "./NewIncidentForm";
import requestHandler from "../components/common/requestHandler";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoteIcon from '@mui/icons-material/Note';

const CADDashboard = () => {
  const [incidents, setIncidents] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [resources, setResources] = useState([]); // Add resource state
  const [loading, setLoading] = useState(true);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [newIncidentFormOpen, setNewIncidentFormOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const incidentResponse = await requestHandler("/api/cad/incidents", "GET");
        const employeeResponse = await requestHandler("/api/employees", "GET");
        const resourceResponse = await requestHandler("/api/resources", "GET");

        setIncidents(Array.isArray(incidentResponse) ? incidentResponse : []);
        setEmployees(Array.isArray(employeeResponse) ? employeeResponse : []);
        setResources(Array.isArray(resourceResponse) ? resourceResponse : []); // Set resources
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        setIncidents([]);
        setEmployees([]);
        setResources([]);
      }
    };

    fetchData();
  }, []);

  const handleEmployeeAssignment = async (incidentId, employeeId) => {
    try {
      const selectedEmployee = employees.find((emp) => emp._id === employeeId);
      const { fullName, jobTitle } = selectedEmployee;

      await axios.put(`/api/cad/incidents/${incidentId}/assign`, {
        userId: employeeId,
        name: fullName,
        role: jobTitle,
        status: "Assigned",
      });

      const updatedIncidents = await axios.get("/api/cad/incidents");
      setIncidents(updatedIncidents.data);
    } catch (error) {
      console.error("Error assigning employee:", error);
    }
  };

  const handleNewIncident = (incident) => {
    setIncidents([incident, ...incidents]);
  };

  const handleDeleteIncident = async (incidentId) => {
    try {
      await axios.delete(`/api/cad/incidents/${incidentId}`);
      setIncidents(incidents.filter(incident => incident._id !== incidentId));
    } catch (error) {
      console.error("Error deleting incident:", error);
    }
  };

  const handleViewIncident = (incidentId) => {
    navigate(`/cad/incident/${incidentId}`);
  };

  const handleEditIncident = (incidentId) => {
    navigate(`/cad/incident/${incidentId}/edit`);
  };

  const handleOpenEPR = (incidentId) => {
    navigate(`/cad/incident/${incidentId}/epr`);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        CAD Dashboard
      </Typography>
      <Divider />
      <Button variant="contained" component={Link} to="/shift-management">
        Shift Management
      </Button>
      <Button
        variant="contained"
        component={Link}
        to="/resource-management"
        sx={{ ml: 2 }}
      >
        Resource Management
      </Button>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h6" gutterBottom>
        Incidents
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          {incidents.map((incident) => (
            <ListItem key={incident._id}>
              <ListItemText
                primary={incident.callType}
                secondary={incident.status}
              />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton color="primary" onClick={() => handleViewIncident(incident._id)}>
                  <VisibilityIcon />
                </IconButton>
                <IconButton color="primary" onClick={() => handleEditIncident(incident._id)}>
                  <EditIcon />
                </IconButton>
                <IconButton color="primary" onClick={() => handleOpenEPR(incident._id)}>
                  <NoteIcon />
                </IconButton>
                <IconButton color="secondary" onClick={() => handleDeleteIncident(incident._id)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </ListItem>
          ))}
        </List>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => setNewIncidentFormOpen(true)}
      >
        New Incident
      </Button>

      <NewIncidentForm
        open={newIncidentFormOpen}
        onClose={() => setNewIncidentFormOpen(false)}
        onIncidentCreated={(newIncident) => {
          setIncidents([newIncident, ...incidents]);
        }}
      />
    </Container>
  );
};

export default CADDashboard;
