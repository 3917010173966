import axios from "axios";

// Create an axios instance with a base URL and headers
const apiClient = axios.create({
  baseURL: "https://dceventmedical.co.uk", // Base URL for API requests
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to automatically include the authorization token
apiClient.interceptors.request.use(
  (config) => {
    // Retrieve the token from local storage
    const token = localStorage.getItem("authToken");

    // Add the Authorization header if a token exists
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config; // Return the updated config
  },
  (error) => {
    console.error(`Error in request interceptor: ${error.message}`);
    return Promise.reject(error); // Reject the request with an error
  }
);

// Add a response interceptor for error handling
apiClient.interceptors.response.use(
  (response) => {
    // Successfully received a response
    return response;
  },
  (error) => {
    // Handle the response error
    console.error(`Error in response interceptor: ${error.message}`);

    // Parse JSON error response for detailed message if available
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred";

    return Promise.reject(new Error(errorMessage));
  }
);

const requestHandler = async (url, method, data = {}) => {
  try {
    // Log the data being sent
    console.log("Raw Request Data:", data);

    const config = {
      url,
      method,
    };

    // Only include data for methods that support a request body
    if (method !== "GET" && method !== "DELETE" && data) {
      config.data = data; // Attach data to the request if applicable
    }

    const response = await apiClient(config);

    // Log the response for debugging
    console.log("API Response:", response);

    // Check if the response content-type is JSON
    const contentType = response.headers["content-type"];
    if (contentType && contentType.includes("application/json")) {
      return response.data; // Return parsed JSON data
    } else if (response.status === 204) {
      // Handle no-content response
      return { message: "No content" };
    } else {
      // Return raw response if not JSON
      console.warn(`Unexpected content-type: ${contentType}`);
      return response.data || { message: "Response received but not JSON" };
    }
  } catch (error) {
    // Handle axios errors
    if (error.response) {
      // The request was made and the server responded with a status code outside of the 2xx range
      console.error(`Error response from server: ${error.message}`);
      const errorMessage =
        error.response.data.message ||
        `Request failed with status ${error.response.status}`;
      throw new Error(errorMessage);
    } else if (error.request) {
      // The request was made but no response was received
      console.error(`No response received from server: ${error.message}`);
      throw new Error("No response from server");
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error(`Error setting up request: ${error.message}`);
      throw new Error("Request setup failed");
    }
  }
};

export default requestHandler;
