import React from "react";
import { Box, Container, Typography, Grid, Button, IconButton, Link as MuiLink } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DCLogo from "../assets/images/DCLogo.png"; // Ensure correct path to your logo

const Footer = () => {
  const theme = useTheme();

  return (
    <Box sx={{ mt: 5, py: 3, backgroundColor: theme.palette.primary.dark, color: "#fff", textAlign: "center" }}>
      <Container>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src={DCLogo}
              alt="DC Event Medical Logo"
              sx={{ height: 60, mb: 2 }}
            />
            <Typography variant="body2" sx={{ mb: 2 }}>
              DC Event Medical provides expert medical services for events of all sizes. Our team is dedicated to ensuring the safety and well-being of your attendees.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Quick Links
            </Typography>
            <Box>
              <Button color="inherit" component={MuiLink} href="/" sx={{ color: "#fff", mb: 1 }}>
                Home
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body2" sx={{ mt: 4 }}>
          &copy; {new Date().getFullYear()} DC Event Medical. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
