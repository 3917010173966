// src/App.js

import React, { Suspense, lazy, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/common/NavBar";
import { AuthProvider } from "./context/AuthContext";
import Footer from "./components/common/Footer";
import Loader from "./components/common/Loader"; // Import the Loader component
import ErrorBoundary from "./ErrorBoundary";
import CADDashboard from "./cad/CADDashboard";
import PatientForm from "./epcr/PatientForm";
import ApplicantList from "./hr/ApplicantList";
import {syncAllTables} from "./syncOfflineData";
import HomeDashboard from "./components/Home";

const theme = createTheme({
  palette: {
    primary: { main: "#1976d2" },
    secondary: { main: "#9c27b0" },
    success: { main: "#4caf50" },
    error: { main: "#f44336" },
    warning: { main: "#ff9800" },
    info: { main: "#2196f3" },
    purple: { main: "#673ab7", dark: "#512da8" },
  },
});

const App = () => {
  useEffect(() => {
    // Syncing offline data when the app comes back online
    window.addEventListener("online", async () => {
      await syncAllTables(); // Sync all tables
      alert("Offline data has been synced to the server.");
    });

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("online", () => {});
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <Router>
          <AuthProvider>
            <NavBar />
            <div className="container">
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route path="/" component={<HomeDashboard />} />
                  <Route path="/cad" component={<CADDashboard />} />
                  <Route path="/epcr" component={<PatientForm />} />
                  <Route path="/hr" component={<ApplicantList />} />
                </Routes>
              </Suspense>
            </div>
          </AuthProvider>
          <Footer />
        </Router>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
