import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  MenuItem,
  Grid,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import axios from "axios";
import AddPatientDialog from "./AddPatientDialog";

// Helper function to format the date-time for the input field
const formatDateTimeLocal = (isoString) => {
  const date = new Date(isoString);
  const pad = (num) => String(num).padStart(2, "0");

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
};

const NewIncidentForm = ({ open, onClose, onIncidentCreated }) => {
  const [formData, setFormData] = useState({
    callType: "",
    location: {
      fullAddress: "",
      w3wIdentifier: "",
    },
    priority: "Low",
    assignedStaff: "",
    description: "",
    notes: "",
    patientPresentationTime: "",
    treatmentStartTime: "",
    treatmentEndTime: "",
    dischargeTime: "",
    dateTime: formatDateTimeLocal(new Date().toISOString()), // Set to current date-time by default
    patient: null, // Store selected patient here
  });

  const [shifts, setShifts] = useState([]);
  const [staffMembers, setStaffMembers] = useState([]);
  const [autoAssign, setAutoAssign] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [patientDialogOpen, setPatientDialogOpen] = useState(false); // To manage the state of patient dialogs
  const [searchDialogOpen, setSearchDialogOpen] = useState(false); // To manage the state of search dialog
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const callCategories = {
    "Category 1": [
      "Cardiac arrest",
      "Severe allergic reaction (anaphylaxis)",
      "Major trauma",
    ],
    "Category 2": ["Stroke", "Chest pain", "Seizure", "Serious burns"],
    "Category 3": [
      "Abdominal pain",
      "Head injury",
      "Falls",
      "Non-severe burns",
    ],
    "Category 4": ["Minor wounds", "Back pain", "Vomiting/Diarrhea"],
  };

  const autoAssignPriority = (callType) => {
    switch (callType) {
      case "Cardiac arrest":
        return "Category 1";
      case "Stroke":
      case "Severe bleeding":
        return "Category 2";
      default:
        return "Category 3";
    }
  };

  useEffect(() => {
    const fetchShiftsAndStaff = async () => {
      try {
        const shiftResponse = await axios.get("/api/cad/shifts", {
          params: { date: new Date() },
        });

        if (shiftResponse.data) {
          const staff = shiftResponse.data.flatMap(
            (shift) => shift.assignedStaff
          );
          setStaffMembers(staff);
        } else {
          setStaffMembers([]);
        }
      } catch (error) {
        console.error("Error fetching shifts and staff:", error);
        setStaffMembers([]);
      }
    };

    fetchShiftsAndStaff();

    if (open) {
      // Set the dateTime field to the current time when the form opens
      const currentDateTime = formatDateTimeLocal(new Date().toISOString());
      setFormData((prevData) => ({
        ...prevData,
        dateTime: currentDateTime,
      }));
    }
  }, [open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      location: {
        ...formData.location,
        [name]: value,
      },
    });
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setAutoAssign(checked);
  };

  const handlePatientSelect = (selectedPatient) => {
    // Validate the selected patient
    if (!selectedPatient || !selectedPatient._id) {
      console.error("Invalid patient selection. Please try again.");
      return;
    }

    // Update the formData with the selected patient using the previous state
    setFormData((prevData) => ({
      ...prevData,
      patient: selectedPatient,
    }));

    // Close the search dialog after successful selection
    setSearchDialogOpen(false);
  };

  const handleSearchPatient = async () => {
    try {
      const response = await axios.get("/api/patients/search", {
        params: { query: searchQuery }, // Send the search query as a parameter
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error searching for patients:", error);
      setSearchResults([]);
    }
  };

  const handlePatientAdded = async (newPatient) => {
    try {
      const response = await axios.post("/api/patients", newPatient);
      setFormData((prevData) => ({
        ...prevData,
        patient: response.data, // Ensure patient ID is stored
      }));
      setPatientDialogOpen(false);
    } catch (error) {
      console.error("Error adding patient:", error);
    }
  };

  const handleSubmit = async () => {
    const priority = autoAssignPriority(formData.callType);
    formData.priority = priority;

    let epcr = null;
    let incidentId = null;

    // Determine the assigned resources
    let assignedResources = [];

    if (autoAssign) {
      assignedResources = staffMembers.map((staff) => ({
        userId: staff._id,
        name: staff.name,
        role: staff.role,
        status: "Assigned",
      }));
    } else if (formData.assignedStaff) {
      const selectedStaff = staffMembers.find(
        (staff) => staff._id === formData.assignedStaff
      );
      if (selectedStaff) {
        assignedResources.push({
          userId: selectedStaff._id,
          name: selectedStaff.name,
          role: selectedStaff.role,
          status: "Assigned",
        });
      }
    }

    try {
      // First, create the EPCR
      if (formData.patient) {
        const epcrResponse = await axios.post("/api/epcr", {
          patient: formData.patient._id,
          incident: null, // Incident ID will be added later
        });
        epcr = epcrResponse.data._id; // Store the created EPCR ID
      }

      // Now create the incident and link it to the created EPCR
      const incidentResponse = await axios.post("/api/cad/incidents", {
        callType: formData.callType,
        location: formData.location,
        priority: formData.priority,
        description: formData.description,
        notes: formData.notes,
        dateTime: formData.dateTime,
        assignedResources,
        epcr: epcr // Pass the EPCR ID here
      });

      incidentId = incidentResponse.data._id;

      // Update the EPCR with the incident ID
      if (epcr) {
        await axios.put(`/api/epcr/${epcr}`, {
          incident: incidentId,
        });
      }

      onIncidentCreated(incidentResponse.data);
      onClose();
    } catch (error) {
      console.error("Error creating incident or EPCR:", error);
    }
  };


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Incident</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Category"
              name="category"
              select
              fullWidth
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              required
            >
              {Object.keys(callCategories).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {selectedCategory && (
            <Grid item xs={12}>
              <TextField
                label="Call Type"
                name="callType"
                select
                fullWidth
                value={formData.callType}
                onChange={handleInputChange}
                required
              >
                {callCategories[selectedCategory].map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="Full Address"
              name="fullAddress"
              fullWidth
              value={formData.location.fullAddress}
              onChange={handleLocationChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="What3Words Identifier"
              name="w3wIdentifier"
              fullWidth
              value={formData.location.w3wIdentifier}
              onChange={handleLocationChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Priority"
              name="priority"
              select
              fullWidth
              value={formData.priority}
              onChange={handleInputChange}
            >
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="High">High</MenuItem>
            </TextField>
          </Grid>

          {/* Add Patient and Search Patient Buttons */}
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <div>
              <Button onClick={() => setPatientDialogOpen(true)}>
                Add Patient
              </Button>
              <AddPatientDialog
                open={patientDialogOpen}
                onClose={() => setPatientDialogOpen(false)}
                onPatientAdded={handlePatientAdded}
              />
            </div>
            <Button onClick={() => setSearchDialogOpen(true)}>
              Search Patient
            </Button>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              fullWidth
              multiline
              rows={4}
              value={formData.description}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Notes"
              name="notes"
              fullWidth
              multiline
              rows={2}
              value={formData.notes}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoAssign}
                  onChange={handleCheckboxChange}
                  name="autoAssign"
                />
              }
              label="Automatically Assign Resources Based on Shifts"
            />
          </Grid>
          {!autoAssign && (
            <Grid item xs={12}>
              <TextField
                label="Assign Staff"
                name="assignedStaff"
                select
                fullWidth
                value={formData.assignedStaff}
                onChange={handleInputChange}
              >
                {staffMembers.length > 0 ? (
                  staffMembers.map((staff) => (
                    <MenuItem key={staff._id} value={staff._id}>
                      {staff.name} - {staff.role}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No staff available</MenuItem>
                )}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="Incident Date and Time"
              name="dateTime"
              type="datetime-local"
              fullWidth
              value={formData.dateTime}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Patient Presentation Time"
              name="patientPresentationTime"
              type="datetime-local"
              fullWidth
              value={formData.patientPresentationTime}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Treatment Start Time"
              name="treatmentStartTime"
              type="datetime-local"
              fullWidth
              value={formData.treatmentStartTime}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Treatment End Time"
              name="treatmentEndTime"
              type="datetime-local"
              fullWidth
              value={formData.treatmentEndTime}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Discharge Time"
              name="dischargeTime"
              type="datetime-local"
              fullWidth
              value={formData.dischargeTime}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Create
        </Button>
      </DialogActions>

      {/* Search Patient Dialog */}
      <Dialog
        open={searchDialogOpen}
        onClose={() => setSearchDialogOpen(false)}
      >
        <DialogTitle>Search Patient</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Search"
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Enter patient name, phone number, etc."
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={handleSearchPatient}>
                Search
              </Button>
            </Grid>
            {searchResults.length > 0 && (
              <Grid item xs={12}>
                <List>
                  {searchResults.map((patient) => (
                    <ListItem
                      button
                      key={patient._id}
                      onClick={() => handlePatientSelect(patient)}
                    >
                      <ListItemText
                        primary={`${patient.firstName} ${patient.lastName}`}
                        secondary={`DOB: ${new Date(
                          patient.dob
                        ).toLocaleDateString()} | Phone: ${
                          patient.phoneNumber
                        }`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSearchDialogOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default NewIncidentForm;
