import axios from "axios";
import {
  getPatientsFromIndexedDB,
  getClinicalNotesFromIndexedDB,
  // Import other IndexedDB functions as needed
} from "./indexedDB"; // Import IndexedDB helpers

// Helper function to sync data for a specific table
async function syncTable(tableName, getDataFromIndexedDB, apiEndpoint) {
  const data = await getDataFromIndexedDB();
  if (data.length > 0) {
    for (let record of data) {
      try {
        await axios.post(apiEndpoint, record);
        console.log(
          `Synced record from ${tableName}: ${JSON.stringify(record)}`
        );
      } catch (error) {
        console.error(`Error syncing ${tableName} record:`, error);
      }
    }
  }
}

// Function to sync all tables
export async function syncAllTables() {
  // Sync patients
  await syncTable("Patients", getPatientsFromIndexedDB, "/api/epcr/patients");

  // Sync clinical notes
  await syncTable(
    "ClinicalNotes",
    getClinicalNotesFromIndexedDB,
    "/api/epcr/clinicalNotes"
  );

  // Add similar calls for other tables, e.g., Medications, Observations, etc.
  // await syncTable("Medications", getMedicationsFromIndexedDB, "/api/epcr/medications");
}
